/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import {
    func, object, shape, string,
} from 'prop-types';
import QueryString from 'qs';
import mbpLogger from 'mbp-logger';

import { GRAPHQL_ENV } from '../../../gql';
import { setPassportCampaignData } from '../../../../state/ducks/Passport/Passport-Action';
import findProductPageByPartNumber from '../../../gql/queries/findProductPageByPartNumber';
import { getBrand } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getPassportData } from '../../../../state/ducks/Passport/Passport-Selectors';

const GraphqlPassportCampaignContainer = ({
    brand, history, setPassportCampaign, passportData,
}) => {
    // getting utm params from url
    const urlParams = history?.location?.search ? QueryString.parse(history.location.search.replace('?', '')) : {};
    let activePassportCampaign = {};
    let campaignData = { enable: false };

    // campaign details from URL
    const campaignName = urlParams?.utm_campaign ? urlParams.utm_campaign.toString().toLowerCase() : null;
    const campaignParam = urlParams?.utm_param ? urlParams.utm_param.toString().toLowerCase() : null;
    const campaignMedium = urlParams?.utm_medium ? urlParams.utm_medium.toString().toLowerCase() : null;

    if (campaignName || campaignParam || campaignMedium) {
        const pricing_params = passportData?.passport_pricing_conditional_logic?.utm_params || [];
        pricing_params.forEach((pricing_param) => {
            const startDate = pricing_param?.start_date;
            const endDate = pricing_param?.end_date;
            const getTime = (date) => new Date(date).getTime();
            const today = () => new Date().getTime();
            if (pricing_param?.enable
                // condition: either campaignName or campaignParam needs to be matched
                && ((campaignName && pricing_param?.utm_campaign
                    && campaignName === pricing_param.utm_campaign.toLowerCase())
                    || (campaignParam && pricing_param?.utm_param
                    && campaignParam === pricing_param.utm_param.toLowerCase())
                    || (campaignMedium && pricing_param?.utm_medium
                        && campaignMedium === pricing_param.utm_medium.toLowerCase()))
                && startDate
                && endDate
                && today() < getTime(endDate)
                && today() > getTime(startDate)) {
                activePassportCampaign = pricing_param;
            }
        });
    }

    if (activePassportCampaign?.passport_referenced_sku?.[0]?.item_sku) {
        const PRODUCT_BY_PART = findProductPageByPartNumber(brand?.domain, activePassportCampaign.passport_referenced_sku[0].item_sku);
        const { error, data } = useQuery(PRODUCT_BY_PART, {
            // making this call every-time app loads
            fetchPolicy: 'network-only',
        });
        if (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                query: PRODUCT_BY_PART,
                component: 'GraphqlPassportCampaignContainer.js',
                message: 'Error loading data from Graphql',
                env: GRAPHQL_ENV,
                error,
            });
            return null;
        }
        if (data) {
            const passportProducts = data?.findProductPageByPartNumber?.product;
            if (passportProducts?.productSkus?.length && activePassportCampaign?.passport_referenced_sku?.[0].passport_item_sku) {
                const campaignPassport = activePassportCampaign?.passport_referenced_sku?.[0];
                passportProducts.productSkus.forEach((sku) => {
                    if (sku?.id === campaignPassport.passport_item_sku) {
                        const prices = sku?.prices || [];
                        prices.forEach((selectedSku) => {
                            if (selectedSku?.type === campaignPassport?.price_type) {
                                campaignData = {
                                    enable: true,
                                    price: selectedSku.value,
                                    itemSku: sku?.id,
                                    longDescription: passportProducts?.longDescription,
                                    passportEnrolmentTerm: campaignPassport?.term_for_enrolment_page,
                                    disclaimer: activePassportCampaign?.disclaimer_for_enrolment_page,
                                    checkoutPageMessage: activePassportCampaign?.checkout_page_message,
                                    productPageMessage: activePassportCampaign?.pdpage_message,
                                    mobileBanner: activePassportCampaign?.mobile_banner_image_for_enrolment_page,
                                    desktopBanner: activePassportCampaign?.desktop_banner_image_for_enrolment_page,
                                    startDate: activePassportCampaign?.start_date,
                                    endDate: activePassportCampaign?.end_date,
                                };
                            }
                        });
                    }
                });
            }
            setPassportCampaign(campaignData);
        }
    }

    return null;
};

GraphqlPassportCampaignContainer.propTypes = {
    brand: shape({
        domain: string,
    }),
    history: object,
    setPassportCampaign: func,
    passportData: object,
};

GraphqlPassportCampaignContainer.defaultProps = {
    brand: {},
    history: {},
    setPassportCampaign: () => {},
    passportData: {},
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    passportData: getPassportData(state),
});
const mapDispatchToProps = (dispatch) => ({
    setPassportCampaign: bindActionCreators(setPassportCampaignData, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GraphqlPassportCampaignContainer));
